// import React, { Component } from 'react';
// import { GetUserLogin, GetOrderDetails } from '../../../../../services';
// import { NotificationManager } from 'react-notifications';
// import Moment from 'react-moment';
// import '../../css/index.css';

// export default class List extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             user: '',
//             orderList: [],
//             isLoading: true,
//             error: null
//         };
//     }

//     async componentDidMount() {
//         let email = sessionStorage.getItem('email');

//         if (email) {
//             try {
//                 // Fetch user details from the API
//                 const userDetails = await GetUserLogin.getCustomerDetail(email);
//                 console.log('User Details:', userDetails);

//                 if (userDetails && userDetails.data) {
//                     const id = userDetails.data.id;
//                     console.log(id)

//                     // Fetch order details with product information
//                     const orderDetails = await GetOrderDetails.fetchOrderDetailsWithProduct(id);
//                     console.log('Order Details:', orderDetails);

//                     if (orderDetails && orderDetails.data) {
//                         this.setState({
//                             user: userDetails.data,
//                             orderList: orderDetails.data,
//                             isLoading: false
//                         });
//                     } else {
//                         NotificationManager.error("No orders found", "Error");
//                         this.setState({ isLoading: false });
//                     }
//                 } else {
//                     NotificationManager.error("Invalid user credentials", "Error");
//                     this.setState({ isLoading: false });
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 NotificationManager.error("Failed to fetch data", "Error");
//                 this.setState({ error: "Failed to fetch data", isLoading: false });
//             }
//         } else {
//             NotificationManager.error("No email found in session storage", "Error");
//             this.setState({ isLoading: false });
//         }
//     }

//     handleLogout = async (event) => {
//         event.preventDefault();
//         await GetUserLogin.logout();
//     }

//     render() {
//         const { user, orderList, isLoading, error } = this.state;

//         if (isLoading) {
//             return <div>Loading...</div>;
//         }

//         if (error) {
//             return <div>Error: {error}</div>;
//         }

//         return (
//             <div className="wrapper">
//                 <div className="gambo-Breadcrumb">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb">
//                                         <li className="breadcrumb-item">Home</li>
//                                         <li className="breadcrumb-item active" aria-current="page">My Orders</li>
//                                     </ol>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dashboard-group">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-12">
//                                 <div className="user-dt">
//                                     <div className="user-img">
//                                         <img src="/img/avatar/img-5.jpg" alt="" />
//                                         <div className="img-add">
//                                             <input type="file" id="file" />
//                                             <label htmlFor="file"><i className="uil uil-camera-plus" /></label>
//                                         </div>
//                                     </div>
//                                     <h4>{user.fullName}</h4>
//                                     <p>{user.phone}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-3 col-md-4">
//                                 <div className="left-side-tabs">
//                                     <div className="dashboard-left-links">
//                                         <a href="/account/view" className="user-item"><i className="uil uil-apps" />Overview</a>
//                                         <a href="/account/profile" className="user-item"><i className="mdi mdi-account-outline" />My profile</a>
//                                         <a href="/account/order" className="user-item active"><i className="uil uil-box" />My Orders</a>
//                                         <a href="/account/wishlist" className="user-item"><i className="uil uil-heart" />Shopping Wishlist</a>
//                                         <a href="/account/address" className="user-item"><i className="uil uil-location-point" />My Address</a>
//                                         <a className="user-item" onClick={this.handleLogout}><i className="uil uil-exit" />Logout</a>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-9 col-md-8">
//                                 <div className="dashboard-right">
//                                     <div className="row">
//                                         <div className="col-md-12">
//                                             <div className="main-title-tab">
//                                                 <h4><i className="uil uil-box" />My Orders</h4>
//                                             </div>
//                                         </div>
//                                         <div className="col-lg-12 col-md-12">
//                                             <div className="pdpt-bg">
//                                                 <div className="pdpt-title">
//                                                     <h6>Order List</h6>
//                                                 </div>
//                                                 <div className="order-body10">
//                                                     <div className="card card-body account-right">
//                                                         <div className="widget">
//                                                             <div className="order-list-tabel-main table-responsive">
//                                                                 <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellSpacing="0">
//                                                                     <thead>
//                                                                         <tr>
//                                                                             <th>Date Purchased</th>
//                                                                             <th>Product Image</th>
//                                                                             <th>Variant Name</th>
//                                                                             <th>Download</th>
//                                                                         </tr>
//                                                                     </thead>
//                                                                     <tbody>
//                                                                         {
//                                                                             orderList && orderList.length > 0 ?
//                                                                                 orderList.map((order, index) => (
//                                                                                     <tr key={index}>
//                                                                                         <td><Moment format='MMMM Do YYYY'>{order.orderDate}</Moment></td>
//                                                                                         <td>
//                                                                                             {order.productImage !== 'N/A' ?
//                                                                                                 <img src={order.productImage} alt="Product" width="50" height="50" />
//                                                                                                 : <span>No Image</span>
//                                                                                             }
//                                                                                         </td>
//                                                                                         <td>{order.variantName !== 'N/A' ? order.variantName : 'No Variant'}</td>
//                                                                                         <td>
//                                                                                             {order.variantPath ?
//                                                                                                 <a href={order.variantPath} target="_blank" rel="noopener noreferrer">Download</a>
//                                                                                                 : <span>No Download</span>
//                                                                                             }
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                 ))
//                                                                                 : <tr><td colSpan="4">No orders found.</td></tr>
//                                                                         }
//                                                                     </tbody>
//                                                                 </table>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }


// import React, { Component } from 'react';
// import { GetUserLogin, GetOrderDetails } from '../../../../../services';
// import { NotificationManager } from 'react-notifications';
// import { Link } from 'react-router-dom';
// import Moment from 'react-moment';
// import '../../css/index.css';

// export default class List extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             customer: '',
//             user: '',
//             orderList: []
//         };
//     }

//     async componentDidMount() {
//         let email = sessionStorage.getItem('email');
//         if (email) {
//             let value = await GetUserLogin.getCustomerDetail(email);
//             if (value && value.data) {
//                 // Set user data into the state first
//                 this.setState({ customer: value.data, user: value.data, }, async () => {
//                     // Use this.state.user.id to get orders
//                     let list = await GetOrderDetails.getOrderByUser(this.state.customer.id);
//                     this.setState({ orderList: list.order });
//                 });
//             } else {
//                 NotificationManager.error("Check your credentials", "Login");
//             }
//         }
//     }

//     handleLogout = async (event) => {
//         event.preventDefault();
//         await GetUserLogin.logout();
//     }

//     render() {
//         let { user, orderList } = this.state;
//         console.log("List -> render -> orderList", orderList);

//         return (
//             <div className="wrapper">
//                 <div className="gambo-Breadcrumb">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb">
//                                         <li className="breadcrumb-item">Home</li>
//                                         <li className="breadcrumb-item active" aria-current="page">My Orders</li>
//                                     </ol>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dashboard-group">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-12">
//                                 <div className="user-dt">
//                                     <div className="user-img">
//                                         <img src="/img/avatar/img-5.jpg" alt="" />
//                                         <div className="img-add">
//                                             <input type="file" id="file" />
//                                             <label htmlFor="file"><i className="uil uil-camera-plus" /></label>
//                                         </div>
//                                     </div>
//                                     <h4>{user.fullName}</h4>
//                                     <p>+91 {user.phone}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className>
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-3 col-md-4">
//                                 <div className="left-side-tabs">
//                                     <div className="dashboard-left-links">
//                                         <a href="/account/view" className="user-item"><i className="uil uil-apps" />Overview</a>
//                                         <a href="/account/profile" className="user-item"><i className="mdi mdi-account-outline" />My profile</a>
//                                         <a href="/account/order" className="user-item active"><i className="uil uil-box" />My Orders</a>
//                                         <a href="/account/wishlist" className="user-item"><i className="uil uil-heart" />Shopping Wishlist</a>
//                                         <a href="/account/address" className="user-item"><i className="uil uil-location-point" />My Address</a>
//                                         <a className="user-item" onClick={this.handleLogout}><i className="uil uil-exit" />Logout</a>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-9 col-md-8">
//                                 <div className="dashboard-right">
//                                     <div className="row">
//                                         <div className="col-md-12">
//                                             <div className="main-title-tab">
//                                                 <h4><i className="uil uil-box" />My Orders</h4>
//                                             </div>
//                                         </div>
//                                         <div className="col-lg-12 col-md-12">
//                                             <div className="pdpt-bg">
//                                                 <div className="pdpt-title">
//                                                     <h6>Order List</h6>
//                                                 </div>
//                                                 <div className="order-body10">
//                                                     <div className="card card-body account-right">
//                                                         <div className="widget">
//                                                             <div className="order-list-tabel-main table-responsive">
//                                                                 <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellspacing="0">
//                                                                     <thead>
//                                                                         <tr>
//                                                                             <th>Order #</th>
//                                                                             <th>Date Purchased</th>
//                                                                             <th>Total</th>
//                                                                             <th>Product Image</th>
//                                                                             <th>Order Paths</th>
//                                                                             <th>Action</th>
//                                                                         </tr>
//                                                                     </thead>
//                                                                     <tbody>
//                                                                         {
//                                                                             orderList && orderList.length > 0 ?
//                                                                                 orderList.map((row, index) => {
//                                                                                     // Inspect the raw data
//                                                                                     console.log("Order Paths Raw Data:", row.orderPaths);

//                                                                                     // Safely handle potential JSON issues
//                                                                                     let orderPaths = [];
//                                                                                     try {
//                                                                                         // Ensure double parsing if needed
//                                                                                         orderPaths = JSON.parse(row.orderPaths);
//                                                                                         if (typeof orderPaths === 'string') {
//                                                                                             orderPaths = JSON.parse(orderPaths);
//                                                                                         }
//                                                                                     } catch (error) {
//                                                                                         console.error("JSON Parse Error:", error);
//                                                                                     }

//                                                                                     return (
//                                                                                         <React.Fragment key={index}>
//                                                                                             {orderPaths.length > 0 && (
//                                                                                                 <tr>
//                                                                                                     <td rowSpan={orderPaths.length}>#{row.number}</td>
//                                                                                                     <td rowSpan={orderPaths.length}><Moment format='MMMM Do YYYY'>{row.createdAt}</Moment></td>
//                                                                                                     <td rowSpan={orderPaths.length}>₹{row.grandtotal}</td>
//                                                                                                     <td rowSpan={orderPaths.length}>
//                                                                                                         <img
//                                                                                                             crossOrigin='anonymous'
//                                                                                                             src={orderPaths[0].productPhoto}
//                                                                                                             alt={orderPaths[0].productName}
//                                                                                                             style={{ width: '100px', height: '100px', objectFit: 'cover' }}
//                                                                                                         />
//                                                                                                     </td>
//                                                                                                     <td>
//                                                                                                         <strong>Product:</strong> {orderPaths[0].productName}<br />
//                                                                                                         <strong>Variant:</strong> {orderPaths[0].variantName}<br />
//                                                                                                         <a
//                                                                                                             href={orderPaths[0].variantPath}
//                                                                                                             rel="noopener noreferrer"
//                                                                                                         >
//                                                                                                             Download
//                                                                                                         </a><br />
//                                                                                                     </td>
//                                                                                                     <td rowSpan={orderPaths.length}>
//                                                                                                         <Link
//                                                                                                             className="btn btn-info btn-sm"
//                                                                                                             to={{ pathname: '/account/order/details', state: row }}>
//                                                                                                             <i className="mdi mdi-eye"></i>
//                                                                                                         </Link>
//                                                                                                     </td>
//                                                                                                 </tr>
//                                                                                             )}
//                                                                                             {orderPaths.slice(1).map((path, i) => (
//                                                                                                 <tr key={i}>
//                                                                                                     <td>
//                                                                                                         <strong>Product:</strong> {path.productName}<br />
//                                                                                                         <strong>Variant:</strong> {path.variantName}<br />
//                                                                                                         <a
//                                                                                                             href={path.variantPath}
//                                                                                                             rel="noopener noreferrer"
//                                                                                                         >
//                                                                                                             Download
//                                                                                                         </a><br />
//                                                                                                     </td>
//                                                                                                 </tr>
//                                                                                             ))}
//                                                                                         </React.Fragment>
//                                                                                     );
//                                                                                 })
//                                                                                 : <tr><td colSpan="6">No orders found.</td></tr>
//                                                                         }
//                                                                     </tbody>
//                                                                 </table>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

import React, { Component } from 'react';
import { GetUserLogin, GetOrderDetails } from '../../../../../services';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import '../../css/index.css';

export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: '',
            user: '',
            orderList: [],
            searchQuery: '' // State variable for search query
        };
    }

    async componentDidMount() {
        let email = sessionStorage.getItem('email');
        if (email) {
            let value = await GetUserLogin.getCustomerDetail(email);
            if (value && value.data) {
                // Set user data into the state first
                this.setState({ customer: value.data, user: value.data }, async () => {
                    // Use this.state.user.id to get orders
                    let list = await GetOrderDetails.getOrderByUser(this.state.customer.id);
                    this.setState({ orderList: list.order });
                });
            } else {
                NotificationManager.error("Check your credentials", "Login");
            }
        }
    }

    handleLogout = async (event) => {
        event.preventDefault();
        await GetUserLogin.logout();
    }

    handleSearch = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    render() {
        let { user, orderList, searchQuery } = this.state;

        // Filter orders based on search query
        let filteredOrders = orderList.filter(order => {
            let orderPaths = [];
            try {
                orderPaths = JSON.parse(order.orderPaths);
                if (typeof orderPaths === 'string') {
                    orderPaths = JSON.parse(orderPaths);
                }
            } catch (error) {
                console.error("JSON Parse Error:", error);
            }

            // Check if any product name matches the search query
            return orderPaths.some(path => path.productName.toLowerCase().includes(searchQuery.toLowerCase()));
        });
        let vpath = "";
        return (
            <div className="wrapper">
                <div className="gambo-Breadcrumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active" aria-current="page">My Orders</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-group">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="user-dt">
                                    <div className="user-img">
                                        <img src="/img/avatar/img-5.jpg" alt="" />
                                        <div className="img-add">
                                            <input type="file" id="file" />
                                            <label htmlFor="file"><i className="uil uil-camera-plus" /></label>
                                        </div>
                                    </div>
                                    <h4>{user.fullName}</h4>
                                    <p>+91 {user.phone}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <div className="left-side-tabs">
                                <div className="dashboard-left-links">
                                    <a href="/account/view" className="user-item"><i className="uil uil-apps" />Overview</a>
                                    <a href="/account/profile" className="user-item"><i className="mdi mdi-account-outline" />My profile</a>
                                    <a href="/account/order" className="user-item active"><i className="uil uil-box" />My Orders</a>
                                    <a href="/account/wishlist" className="user-item"><i className="uil uil-heart" />Shopping Wishlist</a>
                                    <a href="/account/address" className="user-item"><i className="uil uil-location-point" />My Address</a>
                                    <a className="user-item" onClick={this.handleLogout}><i className="uil uil-exit" />Logout</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="dashboard-right">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-title-tab">
                                            <h4><i className="uil uil-box" />My Orders</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="pdpt-bg">
                                            <div className="pdpt-title">
                                                <h6>Order List</h6>
                                                {/* Search Bar */}
                                                <div className="col-md-10 text-center">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search by product name"
                                                        value={searchQuery}
                                                        onChange={this.handleSearch}
                                                    />
                                                </div>
                                            </div>
                                            <div className="order-body10">
                                                <div className="card card-body account-right">
                                                    <div className="widget">
                                                        <div className="order-list-tabel-main table-responsive">
                                                            <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellspacing="0">
                                                                <thead>
                                                                    <tr>

                                                                        <th>Date Purchased</th>
                                                                        <th>Total</th>
                                                                        <th>Product Image</th>
                                                                        <th>Product Details</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        filteredOrders && filteredOrders.length > 0 ?
                                                                            filteredOrders.map((row, index) => {
                                                                                let orderPaths = [];
                                                                                try {
                                                                                    orderPaths = JSON.parse(row.orderPaths);
                                                                                    if (typeof orderPaths === 'string') {
                                                                                        orderPaths = JSON.parse(orderPaths);
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.error("JSON Parse Error:", error);
                                                                                }

                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td style={{ display: "none" }}>#{row.number}</td>
                                                                                        <td><Moment format='MMMM Do YYYY'>{row.createdAt}</Moment></td>
                                                                                        <td>₹{row.grandtotal}</td>
                                                                                        <td> {orderPaths.length > 0 ? (
                                                                                            orderPaths.map((path, i) => (
                                                                                                <div key={i}>
                                                                                                    <img crossOrigin='anonymous' src={path.productPhoto} alt={path.productName} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                                                                </div>
                                                                                            ))
                                                                                        ) : (
                                                                                            <span>No product Image</span>
                                                                                        )}</td>
                                                                                        <td>
                                                                                            {orderPaths.length > 0 ? (
                                                                                                orderPaths.map((path, i) => (
                                                                                                    <div key={i}>
                                                                                                        <strong>Product:</strong> {path.productName}<br />
                                                                                                        <strong>Variant:</strong> {path.variantName}<br />
                                                                                                        <a
                                                                                                            href={path.variantPath}   // Ensure this is a direct URL to the zip file
                                                                                                            download                   // This attribute triggers the download
                                                                                                            rel="noopener noreferrer"
                                                                                                        >
                                                                                                            Download
                                                                                                        </a><br />

                                                                                                    </div>
                                                                                                ))
                                                                                            ) : (
                                                                                                <span>No paths available</span>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {/* <Link
                                                                                                className="btn btn-info btn-sm"
                                                                                                to={{ pathname: { vpath }, state: row }} >
                                                                                                <i className="mdi mdi-eye"></i>
                                                                                            </Link> */}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : <tr><td colSpan="6">No orders found.</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
